import type { Ref } from 'vue';
import { computed, ref, toRef } from 'vue';

import { useLocalStorageManager } from '@leon-hub/local-storage';

import { useIsLoggedIn } from '@core/auth';

import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type {
  DateTimeRange,
  SportEventsSportsDateTimeFilter,
} from 'web/src/modules/sportline/types';
import { defaultListFilter } from 'web/src/modules/sportline/constants';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { isSportDateTimeFilterGuard } from 'web/src/modules/sportline/submodules/navigation/store/guards';
import { useResetPersistLocation } from 'web/src/modules/sportline/submodules/persist-filters';
import { useSportsListDateFilterStoreComposable } from 'web/src/modules/sportline/submodules/sports-list/store/composables';

interface SportlineSportsFilterComposable {
  selectedSportFamily: Ref<Maybe<string>>;
  sportsListFilter: Ref<SportDateTimeFilter>;
  filterDateTimeInterval: Ref<DateTimeRange>;
  absoluteFilterDateTimeInterval: Ref<DateTimeRange>;
  allowedSportFamily: Ref<string[]>;
  customFilters: Ref<CustomFilter[]>;
  setSelectedSportFamily(id: Maybe<string>): void;
  resetFilterOnLeavingPage(): void;
  setSportsListFilter(value?: Maybe<SportEventsSportsDateTimeFilter>): void;
  saveFilters(): void;
  loadFilters(): void;
}

const localStorageCustomerDateTimeFilterKey = 'customerDateTimeFilter';

export default function useSportlineSportsFilterComposable(): SportlineSportsFilterComposable {
  const localStorageManager = useLocalStorageManager();
  const { isLoggedIn } = useIsLoggedIn();
  const sportlineSettingsStore = useSportlineSettingsStore();
  const allowedSportFamily = toRef(sportlineSettingsStore, 'allowedSportFamily');
  const isZeroMarginEnabled = toRef(sportlineSettingsStore, 'isZeroMarginEnabled');

  const { handlePersistLocationReset } = useResetPersistLocation();

  const {
    dateTimeFilter: sportsListFilter,
    dateTimeFilterInterval: filterDateTimeInterval,
    dateTimeFilterAbsoluteInterval: absoluteFilterDateTimeInterval,
    setDateTimeFilter: setSportsListFilter,
  } = useSportsListDateFilterStoreComposable();

  const selectedSportFamily = ref<Maybe<string>>(null);

  const customFilters = computed<CustomFilter[]>(() => {
    const result: CustomFilter[] = [];
    if (isLoggedIn.value) {
      result.push(CustomFilter.Favorites);
    }
    if (isZeroMarginEnabled.value) {
      result.push(CustomFilter.ZeroMargin);
    }
    return result;
  });

  function setSelectedSportFamily(id: Maybe<string>): void {
    selectedSportFamily.value = id;
  }

  function resetFilterOnLeavingPage(): void {
    handlePersistLocationReset('sports');
    selectedSportFamily.value = null;
  }

  function saveFilters(): void {
    if (!isLoggedIn.value) {
      return;
    }
    localStorageManager.setItem(localStorageCustomerDateTimeFilterKey, JSON.stringify(sportsListFilter.value));
  }

  function loadFilters(): void {
    if (!isLoggedIn.value) {
      return;
    }
    const savedCustomerDateTimeFilter = localStorageManager.getItem(localStorageCustomerDateTimeFilterKey);
    let customerDateTimeFilter: Optional<SportDateTimeFilter>;

    try {
      customerDateTimeFilter = savedCustomerDateTimeFilter ? JSON.parse(savedCustomerDateTimeFilter) : undefined;

      if (
        !customerDateTimeFilter
        || !isSportDateTimeFilterGuard(customerDateTimeFilter)
      ) {
        customerDateTimeFilter = undefined;
      }
    } catch {
      customerDateTimeFilter = undefined;
    }

    if (!customerDateTimeFilter) {
      return;
    }

    // Check is some filter already active
    const isForcedFilter = sportsListFilter.value.activeKickoffPeriod !== defaultListFilter.activeKickoffPeriod;
    const activeKickoffPeriod = isForcedFilter
      ? sportsListFilter.value.activeKickoffPeriod
      : customerDateTimeFilter.activeKickoffPeriod;

    setSportsListFilter({ ...customerDateTimeFilter, activeKickoffPeriod });
  }

  return {
    selectedSportFamily,
    sportsListFilter,
    filterDateTimeInterval,
    absoluteFilterDateTimeInterval,
    allowedSportFamily,
    customFilters,
    setSelectedSportFamily,
    resetFilterOnLeavingPage,
    setSportsListFilter,
    saveFilters,
    loadFilters,
  };
}
