import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';

import { isUndefined } from '@leon-hub/guards';

import type { SportFamily } from 'web/src/modules/sportline/enums';
import type { ListFilter } from 'web/src/modules/sportline/submodules/sports/types';
import type { SportElement, SportEventsSportsDateTimeFilter } from 'web/src/modules/sportline/types';
import type { CoreSportlineFetchOptions, SportsTreeSportElement } from 'web/src/modules/sportline/types/rest';
import { useGlobalSportlineEvents } from 'web/src/modules/sportline/composables/core';
import {
  useIsSportDateTimeFiltersEnabledRef,
  useParseSportlineSettingsRef,
} from 'web/src/modules/sportline/composables/settings';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useSportlinePageIdentifier } from 'web/src/modules/sportline/store/composables';
import useSportsTreeStore from 'web/src/modules/sportline/store/useSportsTreeStore';
import {
  useCustomerFavoritesService,
  useSportlineFavoritesStoreComposable,
} from 'web/src/modules/sportline/submodules/favorites/composables';
import { CustomerFavoritesIdsBackgroundUpdateKey } from 'web/src/modules/sportline/submodules/favorites/enums';
import { useSaveOpenRegionStateStoreComposable } from 'web/src/modules/sportline/submodules/sports-list/store/composables';
import { useSyncSportline } from 'web/src/modules/sportline/submodules/sync-sportline';
import { useSportsSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import { isSportFamilyEquals } from 'web/src/modules/sportline/utils';
import { SportsListAdapter } from 'web/src/modules/sportline/utils/rest';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import useAwaitConditionQueue from 'web/src/utils/store/composables/useAwaitConditionQueue';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';

import useSportlineSportsFilterComposable from './composables/useSportlineSportsFilterComposable';
import useSportlineZeroMarginSportsComposable from './composables/useSportlineZeroMarginSportsComposable';
import {
  filterSportEventsListByFilter,
  getLoadSportsTreeOptions,
  isLoadSportsTreeOptionsEmpty,
} from './utils';

type FetchOptions = CoreSportlineFetchOptions & {
  dateTimeFilter?: Maybe<SportEventsSportsDateTimeFilter>;
};

export const useSportlineSportsStore = defineStore('sportline-sports', () => {
  const apiService = useSportlineApiService();
  const sportsTreeStore = useSportsTreeStore();
  const rawFullSportsList = toRef(sportsTreeStore, 'fullSportsList');

  const isSportFiltersEnabled = useIsSportDateTimeFiltersEnabledRef();
  const parseSportlineSettings = useParseSportlineSettingsRef();

  const { timeout: sportsUpdateInterval } = useSportsSportlineUpdateTimeout();

  const {
    onInit: onGlobalSportlineEventsInit,
  } = useGlobalSportlineEvents();

  const { isFavoritesIdsLoaded } = useCustomerFavoritesService();

  const {
    pageIdentifier,
    setPageIdentifier,
  } = useSportlinePageIdentifier();
  const {
    selectedSportFamily,
    sportsListFilter,
    filterDateTimeInterval,
    absoluteFilterDateTimeInterval,
    allowedSportFamily,
    customFilters,
    resetFilterOnLeavingPage,
    setSelectedSportFamily,
    setSportsListFilter,
    saveFilters,
    loadFilters,
  } = useSportlineSportsFilterComposable();
  const saveOpenRegionState = useSaveOpenRegionStateStoreComposable();

  function prepareEventsList(fullList: SportElement[]): SportElement[] {
    return filterSportEventsListByFilter(fullList, {
      dateTimeRange: absoluteFilterDateTimeInterval.value,
      sportlineType: sportsListFilter.value.sportlineType,
    });
  }

  const {
    isReady: isZeroMarginReady,
    isLoaded: isZeroMarginLoaded,
    isListLoaded: isZeroMarginListLoaded,
    isZeroMarginEnabled,
    isZeroMarginEventsTabSelected,
    zeroMarginEventsList,
    rawZeroMarginEventsList,
    isZeroMarginEventsTabAvailable,
    isZeroMarginEventsListEmpty,
    initialRequests: initialZeroMarginRequests,
    onInit: onZeroMarginInit,
    syncBackgroundRequests: syncZeroMarginBackgroundRequests,
    setBackgroundUpdateEnabled: setZeroMarginBackgroundUpdateEnabled,
  } = useSportlineZeroMarginSportsComposable({
    selectedSportFamily,
    prepareEventsList,
  });
  const {
    favoriteEventsFullList: filteredByDateTimeFavoriteEventsList,
    favoriteSelectedRegionFamily,
    favoriteSelectedSportFamily,
    /** Actual list for display at favorites tab */
    favoriteEventsListForDisplay,
    onInit: onFavoritesInit,
    initialRequests: initialFavoritesRequests,
    setBackgroundUpdateEnabled: setFavoritesBackgroundUpdateEnabled,
    setFavoriteSelectedSportFamily,
    setFavoriteSelectedRegionFamily,
    selectFirstAvailableFavoriteSportFamily,
  } = useSportlineFavoritesStoreComposable({
    backgroundFavoritesId: CustomerFavoritesIdsBackgroundUpdateKey.PREMATCH,
    prepareEventsList,
  });

  const sportsListInternal = ref<Maybe<SportsTreeSportElement[]>>(null);
  const showLoadingIndicator = ref(false);

  const fullSportsList = computed<SportsTreeSportElement[]>(() => (rawFullSportsList.value ?? []));
  const actualSportsList = computed<Maybe<SportsTreeSportElement[]>>(() => {
    const interval = filterDateTimeInterval.value;
    const { sportlineType } = sportsListFilter.value;
    const hasDateTimeFilter = !isUndefined(interval.from) && !isUndefined(interval.to);
    const hasActiveFilter = hasDateTimeFilter || !isUndefined(sportlineType);

    return hasActiveFilter
      ? sportsListInternal.value
      : rawFullSportsList.value;
  });
  /** Active main filter for content */
  const actualListFilter = computed<ListFilter>(() => {
    let sportFamily: SportFamily | string | undefined;

    if (isSportFamilyEquals(selectedSportFamily.value, CustomFilter.Favorites)) {
      sportFamily = CustomFilter.Favorites;
    } else if (selectedSportFamily.value) {
      sportFamily = selectedSportFamily.value;
    } else {
      sportFamily = rawFullSportsList.value?.[0]?.sport.navigationParameters.urlName;

      if (!sportFamily) {
        const sportsList = actualSportsList.value;
        sportFamily = sportsList ? sportsList[0]?.sport.navigationParameters.urlName : undefined;
      }
    }

    return { sportFamily };
  });
  /** Active sport element for content */
  const actualSportElement = computed<Maybe<SportsTreeSportElement>>(() => {
    const sportsList = actualSportsList.value;

    if (!sportsList) {
      return null;
    }

    return sportsList
      .find((item) => isSportFamilyEquals(item.sport, actualListFilter.value.sportFamily)) ?? null;
  });
  const activeListFilterSportElement = computed<Optional<SportsTreeSportElement>>(() => {
    const sportsList = rawFullSportsList.value;

    if (!sportsList) {
      return undefined;
    }

    return selectedSportFamily.value && sportsList
      ? sportsList.find((item) => isSportFamilyEquals(item.sport, selectedSportFamily.value))
      : sportsList[0];
  });
  const isActualSportsListLoaded = computed(() => actualSportsList.value !== null);
  const isLoaded = computed<boolean>(() => (
    isActualSportsListLoaded.value
    && isFavoritesIdsLoaded.value
    && isZeroMarginLoaded.value
  ));

  function setShowLoadingIndicator(state: boolean): void {
    showLoadingIndicator.value = state;
  }

  async function reloadSportsList(silent?: boolean): Promise<void> {
    const loadingOptions = getLoadSportsTreeOptions({
      silent,
      sportlineType: sportsListFilter.value.sportlineType,
      interval: filterDateTimeInterval.value,
    });

    if (isLoadSportsTreeOptionsEmpty(loadingOptions)) {
      // will be used full sports list
      return;
    }

    const result = await apiService.loadSportsTree(loadingOptions);

    sportsListInternal.value = result
      ? (new SportsListAdapter(result, parseSportlineSettings.value)).getSports()
      : [];
  }

  async function reloadListForFilter(
    filter: Maybe<SportEventsSportsDateTimeFilter>,
    silent?: boolean,
  ): Promise<void> {
    setShowLoadingIndicator(true);
    setSportsListFilter(filter);
    saveFilters();
    await reloadSportsList(silent);
    setShowLoadingIndicator(false);
  }

  async function reloadFullSportsList(silent?: boolean): Promise<void> {
    await sportsTreeStore.reloadFullSportsList({
      silent,
      invalidateAfterInterval: sportsUpdateInterval.value,
    });
  }

  const lastUpdate = new BackgroundUpdateStopwatch<['sportsList']>({
    sportsList: async ({ silent }: FetchOptions) => {
      await Promise.all([
        reloadSportsList(silent),
        reloadFullSportsList(silent),
      ]);
      lastUpdate.update('sportsList');
    },
  });

  const {
    awaitCondition: awaitStateIsLoaded,
  } = useAwaitConditionQueue({
    source: 'Sportline sports page is loaded',
    value: isLoaded,
    condition: (value: boolean) => value,
    // It should give some time for the render process in the prerender
    flush: process.env.VUE_APP_PRERENDER ? 'post' : 'sync',
  });
  const {
    initialRequests: initialListRequests,
    syncState: syncListState,
    syncBackgroundRequests: syncListBackgroundRequests,
    setBackgroundUpdateEnabled: setListBackgroundUpdateEnabled,
  } = useBackgroundRequestsLifeCycle({
    lastUpdate,
    updateInterval: sportsUpdateInterval,
  });

  function onListInit(): void {
    useSyncSportline(async (silent: boolean): Promise<void> => {
      await syncListState({ silent });
    }, sportsUpdateInterval);
  }

  async function initialRequests({ dateTimeFilter }: FetchOptions): Promise<void> {
    if (isSportFiltersEnabled.value) {
      if (dateTimeFilter) {
        setSportsListFilter(dateTimeFilter);
      } else {
        loadFilters();
      }
    }

    await Promise.all([
      initialListRequests(),
      initialZeroMarginRequests(),
      initialFavoritesRequests(),
    ]);
  }

  function setBackgroundUpdateEnabled(value: boolean): void {
    void setListBackgroundUpdateEnabled(value);
    void setZeroMarginBackgroundUpdateEnabled(value);
    void setFavoritesBackgroundUpdateEnabled(value);
  }

  function syncBackgroundRequests(): void {
    void syncListBackgroundRequests({ silent: true });
    void syncZeroMarginBackgroundRequests({ silent: true });
  }

  // on init
  onGlobalSportlineEventsInit();
  onListInit();
  onFavoritesInit();
  onZeroMarginInit();

  return {
    ...saveOpenRegionState,
    pageIdentifier,
    setPageIdentifier,
    showLoadingIndicator,
    setShowLoadingIndicator,
    isLoaded,
    isFavoritesIdsLoaded,
    isZeroMarginLoaded,
    isActualSportsListLoaded,
    selectedSportFamily,
    fullSportsList,
    actualSportsList,
    sportsListFilter,
    actualListFilter,
    actualSportElement,
    activeListFilterSportElement,
    customFilters,
    allowedSportFamily,
    isZeroMarginReady,
    isZeroMarginListLoaded,
    isZeroMarginEnabled,
    isZeroMarginEventsTabSelected,
    zeroMarginEventsList,
    isZeroMarginEventsTabAvailable,
    isZeroMarginEventsListEmpty,
    favoriteSelectedRegionFamily,
    favoriteSelectedSportFamily,
    filteredByDateTimeFavoriteEventsList,
    favoriteEventsListForDisplay,
    awaitStateIsLoaded,
    reloadListForFilter,
    setSelectedSportFamily,
    resetFilterOnLeavingPage,
    setSportsListFilter,
    initialRequests,
    initialListRequests,
    initialZeroMarginRequests,
    initialFavoritesRequests,
    setBackgroundUpdateEnabled,
    syncBackgroundRequests,
    setFavoriteSelectedSportFamily,
    setFavoriteSelectedRegionFamily,
    selectFirstAvailableFavoriteSportFamily,

    // @TODO do not export
    rawZeroMarginEventsList,
  };
});
