import type { Ref } from 'vue';
import { computed, ref, toRef } from 'vue';

import type { SportElement } from 'web/src/modules/sportline/types';
import type {
  CoreSportlineFetchOptions,
  GetSportEventsChangesResponse,
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import {
  useIsZeroMarginEnabledRef,
  useParseSportlineSettingsRef,
} from 'web/src/modules/sportline/composables/settings';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useSportlineEventsChangesListeners } from 'web/src/modules/sportline/store/composables';
import useSportsTreeStore from 'web/src/modules/sportline/store/useSportsTreeStore';
import { useSyncSportline } from 'web/src/modules/sportline/submodules/sync-sportline';
import {
  useCountSportlineUpdateTimeout,
  useZeroMarginSportlineUpdateTimeout,
} from 'web/src/modules/sportline/submodules/update-timeout';
import { isSportFamilyEquals } from 'web/src/modules/sportline/utils';
import { BetlineReplaceResponse, SportlineFactory } from 'web/src/modules/sportline/utils/rest';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';

interface SportlineZeroMarginSportsProps {
  selectedSportFamily: Ref<Maybe<string>>;
  prepareEventsList?(fullList: SportElement[]): SportElement[];
}

interface SportlineZeroMarginSportsComposable {
  isReady: Ref<boolean>;
  isLoaded: Ref<boolean>;
  isCountersLoaded: Ref<boolean>;
  isListLoaded: Ref<boolean>;
  isZeroMarginEnabled: Ref<boolean>;
  isZeroMarginEventsTabSelected: Ref<boolean>;
  zeroMarginEventsList: Ref<SportElement[]>;
  rawZeroMarginEventsList: Ref<Maybe<SportElement[]>>;
  isZeroMarginEventsTabAvailable: Ref<boolean>;
  isZeroMarginEventsListEmpty: Ref<boolean>;
  onInit(): void;
  initialRequests(): Promise<void>;
  syncBackgroundRequests(options: { force?: boolean; silent?: boolean }): Promise<void>;
  setBackgroundUpdateEnabled(value: boolean): Promise<void>;
}

function defaultPrepareEventsList(fullList: SportElement[]): SportElement[] {
  return fullList;
}

export default function useSportlineZeroMarginSportsComposable(
  props: SportlineZeroMarginSportsProps,
): SportlineZeroMarginSportsComposable {
  const { selectedSportFamily } = props;
  const prepareEventsList = props.prepareEventsList ?? defaultPrepareEventsList;

  const apiService = useSportlineApiService();
  const broadcastSelected = useBroadcastSelected();
  const sportsTreeStore = useSportsTreeStore();

  const { timeout: countUpdateTimeout } = useCountSportlineUpdateTimeout();
  const { timeout: zeroMarginUpdateTimeout } = useZeroMarginSportlineUpdateTimeout();

  const isZeroMarginEnabled = useIsZeroMarginEnabledRef();
  const parseSportlineSettings = useParseSportlineSettingsRef();

  const isCountersLoaded = toRef(sportsTreeStore, 'isCountersLoaded');
  const hasZeroMarginInTheTree = toRef(sportsTreeStore, 'hasZeroMargin');
  const rawEventsResponse = ref<Maybe<Readonly<GetSportEventsResponse>> | false>(null);
  const isZeroMarginEventsTabSelected = computed<boolean>(
    () => isSportFamilyEquals(selectedSportFamily.value, CustomFilter.ZeroMargin),
  );

  const lastUpdate = new BackgroundUpdateStopwatch<['zeroMarginEvents']>({
    zeroMarginEvents: async ({ silent }: CoreSportlineFetchOptions) => {
      if (!isZeroMarginEnabled.value) {
        return;
      }
      if (!isZeroMarginEventsTabSelected.value) {
        return;
      }

      const response: Maybe<GetSportEventsResponse | GetSportEventsChangesResponse> = await apiService
        .loadZeroMarginEvents({
          // eslint-disable-next-line ts/prefer-nullish-coalescing
          vTag: (rawEventsResponse.value || undefined)?.vtag,
          silent,
        });

      rawEventsResponse.value = response
        ? BetlineReplaceResponse.unknownResponseToSportEventsResponse(response)
        : response;

      broadcastSelected.updateDataInStorageByResponse({ response });
      lastUpdate.update('zeroMarginEvents');
    },
  });
  const countersLastUpdate = new BackgroundUpdateStopwatch<['zeroMarginCounters']>({
    zeroMarginCounters: async ({ silent }: { silent?: boolean }) => {
      if (!isZeroMarginEnabled.value) {
        return;
      }
      if (isZeroMarginEventsTabSelected.value) {
        return;
      }
      await sportsTreeStore.reloadZeroMarginCounters({ silent });
      countersLastUpdate.update('zeroMarginCounters');
    },
  });

  const rawZeroMarginEventsList = computed<Maybe<SportElement[]>>(() => (
    isZeroMarginEnabled.value && rawEventsResponse.value
      ? (new SportlineFactory(rawEventsResponse.value, parseSportlineSettings.value)).build()
      : null
  ));
  const isZeroMarginEventsTabAvailable = computed<boolean>(() => (hasZeroMarginInTheTree.value
    || (!!rawZeroMarginEventsList.value && rawZeroMarginEventsList.value.length > 0)));
  const isZeroMarginEventsListEmpty = computed<boolean>(() => (!rawZeroMarginEventsList.value?.length));
  const zeroMarginEventsList = computed<SportElement[]>(() => (
    prepareEventsList(rawZeroMarginEventsList.value ?? [])
  ));
  /** current selected tab ready */
  const isReady = computed<boolean>(() => {
    if (!isZeroMarginEnabled.value) {
      return true;
    }
    if (!isZeroMarginEventsTabSelected.value) {
      return isCountersLoaded.value;
    }
    return rawEventsResponse.value !== null;
  });
  const isListLoaded = computed(() => {
    if (!isZeroMarginEnabled.value) {
      return true;
    }
    return rawEventsResponse.value !== null;
  });
  /** any zero margin data loaded (used to first loading page indication) */
  const isLoaded = computed<boolean>(() => {
    if (!isZeroMarginEnabled.value) {
      return true;
    }
    return isCountersLoaded.value || isListLoaded.value;
  });

  const {
    initialRequests: initialListRequests,
    syncState: syncListState,
    syncBackgroundRequests: syncListBackgroundRequests,
    setBackgroundUpdateEnabled: setListBackgroundUpdateEnabled,
  } = useBackgroundRequestsLifeCycle({
    lastUpdate,
    updateInterval: zeroMarginUpdateTimeout,
  });
  const {
    initialRequests: initialCountersRequests,
    syncState: syncCountersState,
    syncBackgroundRequests: syncCountersBackgroundRequests,
    setBackgroundUpdateEnabled: setCountersBackgroundUpdateEnabled,
  } = useBackgroundRequestsLifeCycle({
    lastUpdate: countersLastUpdate,
    updateInterval: countUpdateTimeout,
  });

  const {
    onInit: sportlineEventsChangesListenersOnInit,
  } = useSportlineEventsChangesListeners({
    setResponse(response: GetSportEventsResponse): void {
      rawEventsResponse.value = response;
    },
    getResponse(): Maybe<GetSportEventsResponse> {
      // eslint-disable-next-line ts/prefer-nullish-coalescing
      return rawEventsResponse.value || null;
    },
  });

  async function initialRequests(): Promise<void> {
    if (!isZeroMarginEnabled.value) {
      return;
    }
    // we can run requests sequentially because they are mutually exclusive
    await initialCountersRequests();
    await initialListRequests();
  }

  async function syncBackgroundRequests(options: {
    force?: boolean;
    silent?: boolean;
  }): Promise<void> {
    if (!isZeroMarginEnabled.value) {
      return;
    }
    // we can run requests sequentially because they are mutually exclusive
    await syncCountersBackgroundRequests(options);
    await syncListBackgroundRequests(options);
  }

  async function setBackgroundUpdateEnabled(value: boolean): Promise<void> {
    if (!isZeroMarginEnabled.value) {
      return;
    }
    // we can run requests sequentially because they are mutually exclusive
    await setCountersBackgroundUpdateEnabled(value);
    await setListBackgroundUpdateEnabled(value);
  }

  function onInit(): void {
    sportlineEventsChangesListenersOnInit();
    useSyncSportline(async (silent: boolean): Promise<void> => {
      if (!isZeroMarginEnabled.value) {
        return;
      }
      await syncListState({ silent });
    }, zeroMarginUpdateTimeout);
    useSyncSportline(async (silent: boolean): Promise<void> => {
      if (!isZeroMarginEnabled.value) {
        return;
      }
      await syncCountersState({ silent });
    }, countUpdateTimeout);
  }

  return {
    isReady,
    isLoaded,
    isCountersLoaded,
    isListLoaded,
    isZeroMarginEnabled,
    isZeroMarginEventsTabSelected,
    zeroMarginEventsList,
    rawZeroMarginEventsList,
    isZeroMarginEventsTabAvailable,
    isZeroMarginEventsListEmpty,
    onInit,
    initialRequests,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
  };
}
