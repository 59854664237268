import type { DateTimeRange } from 'web/src/modules/sportline/types';
import type { LoadSportsTreeOptions } from 'web/src/modules/sportline/types/services';
import { SportlineType } from 'web/src/modules/sportline/enums';
import { BetlineType } from 'web/src/modules/sportline/enums/rest';

export interface GetLoadSportsTreeOptionsProps {
  silent?: boolean;
  interval?: DateTimeRange;
  sportlineType?: string | number | SportlineType;
}

export function getLoadSportsTreeOptions(props: GetLoadSportsTreeOptionsProps): LoadSportsTreeOptions {
  const {
    silent,
    interval,
    sportlineType,
  } = props;

  const hasDateTimeFilter = interval && interval.from !== undefined && interval.to !== undefined;

  return {
    dateTime: hasDateTimeFilter ? interval : undefined,
    silent,
    betlines: sportlineType === SportlineType.Live
      ? [BetlineType.Live]
      : undefined,
  };
}
